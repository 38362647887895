/* 🏠 Contenedor Principal */
.contenedor-documentos {
    max-width: 1200px;
    margin: auto;
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

/* 📂 Contenedor de Carpetas */
.carpetas-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 1rem 0;
}

/* 🔹 Tarjeta de Carpeta */
.carpeta-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    align-items: center;
    width: 220px;
    height: 60px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;
    cursor: pointer;
    transition: transform 0.2s ease, border-color 0.3s ease;
}

.carpeta-card:hover {
    transform: scale(1.02);
    border-color: #1a73e8;
}

/* 🗂 Ícono de Carpeta */
.carpeta-icono {
    margin-right: 12px;
    color: #fbbf24;
}

/* 📌 Nombre de Carpeta */
.nombre-carpeta {
    font-size: 14px;
    font-weight: 500;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* 📂 Contenedor de Archivos - Asegura que los archivos se alineen en fila */
.archivos-container {
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos pasen a una nueva línea si no caben */
    gap: 15px; /* Espaciado uniforme entre los archivos */
    padding: 1rem 0;
}

/* 📄 Tarjeta de Archivo */
.archivo-card {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    width: 220px;
    height: 60px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;
    cursor: pointer;
    transition: transform 0.2s ease, border-color 0.3s ease;
    margin-top: 10px;
}

.archivo-card:hover {
    transform: scale(1.02);
    border-color: #4caf50;
}

/* 📄 Ícono de Archivo */
.archivo-icono {
    margin-right: 12px;
    color: #4caf50;
}

/* 📌 Nombre del Archivo */
.nombre-archivo {
    font-size: 14px;
    font-weight: 500;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* 🛠 Menú de Opciones */
.carpeta-opciones {
    position: absolute;
    background: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 8px;
    display: none;
    flex-direction: column;
    width: 150px;
    top: 50px;
    right: 0;
}

.opciones-icono:hover + .carpeta-opciones,
.carpeta-opciones:hover {
    display: flex;
}

/* 🚀 Botón de Crear Carpeta */
.button-confirm {
    background-color: #8F3B8C;
    color: white;
    padding: 10px 16px;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.button-confirm:hover {
    background-color: #6d2c6b;
}

/* 📌 Breadcrumbs */
.breadcrumbs {
    font-size: 14px;
    margin-bottom: 10px;
    color: #555;
}

.breadcrumb {
    cursor: pointer;
    color: #007bff;
    font-weight: bold;
    margin-right: 5px;
}

.breadcrumb:hover {
    text-decoration: underline;
}

/* 📂 Drag and Drop Estilos */
.contenedor-documentos.dragging {
    border: 2px dashed #4caf50;
    background-color: rgba(76, 175, 80, 0.1);
}

.drag-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    z-index: 10;
}

.carpeta-card.resaltado {
    border: 2px dashed #4caf50;
    background-color: rgba(76, 175, 80, 0.1);
}
