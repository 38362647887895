.contenedorOptions {
    width: 99.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.3rem;
}

.contenedorInputOptionRegisters {
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.inputComponentRegisters {
    width: 100%;
    padding: 0.5rem 0.8rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    border: 2px solid #ccc;
}

.inputMothComponent {
    width: 60%;
}

.icon-options {
    color: #ccc;
    padding: 0.8rem;
    border: 0.5rem;
    font-size: 0.8rem;
    border: 2px solid #ccc;
    border-radius: 1rem;
    transition: all 0.3s ease;
}

.icon-options-close {
    color: #f05353;
    padding: 0.8rem;
    border: 0.5rem;
    font-size: 0.8rem;
    border: 2px solid #f05353;
    border-radius: 1rem;
    transition: all 0.3s ease;
}

.button-icons-options {
    border: none;
    background-color: #fff;
    cursor: pointer;
}

.icon-options:hover {
    background-color: #2A9AB6;
    border: 2px solid #2A9AB6;
    color: #fff;
}

.icon-options-close:hover {
    background-color: #f05353;
    border: 2px solid #f05353;
    color: #fff;
}

.filter-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 16px;
    gap: 12px;

    width: 102px;
    height: 49px;

    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 12px;
    cursor: pointer;

    margin-right: auto;
}

.filter-button:hover {
    background: #F0F0F0;
}

.filter-icon {
    width: 11px;
    height: 12px;
    font-size: 12px;
    color: #AFAFAF;
}

.filter-text {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #AFAFAF;
}

/* Contenedor para el input con el icono */
.inputSearchContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 35%;
}

/* Icono de búsqueda dentro del input */
.search-icon {
    position: absolute;
    left: 12px;
    font-size: 16px;
    color: #AFAFAF;
}

.inputComponent {
    width: 100%;
    height: 20px;
    padding: 14px 16px;
    padding-left: 40px;
    font-size: 1rem;
    border-radius: 12px;
    border: 1px solid #D9D9D9;
    background: #FFFFFF;
    margin-right: 1rem;
}