.filter-modal-overlay {
    position: fixed;
    top: 0;
    /* Ajusta para que quede arriba */
    left: 0;
    width: 100%;
    height: 100vh;
    /* Asegura que cubra toda la pantalla */
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* Hace que el modal quede arriba */
    z-index: 9999;
    /* Pone el modal al frente de todo */
    padding-top: 60px;
    /* Ajusta el espaciado superior */
}

.filter-modal {
    background: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    width: 303px;
    height: auto;
    /* Se ajusta al contenido */
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 10000;
    /* Asegura que el modal se mantenga en el frente */
}

.filter-modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.cancel-button {
    background: #ccc;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
}

.apply-button {
    background: #8F3B8C;
    color: white;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
}